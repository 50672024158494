import Vue from 'vue'
import App from './App.vue'

import router from './router'

// import echarts from 'echarts'
// var echarts = require("echarts");
// console.log('sfsdf' , echarts)

Vue.config.productionTip = false

//
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI, {size: 'mini'})

// 
import * as directives from './advanced/directives'
Object.keys(directives).forEach(name => {
  Vue.directive(name, directives[name])
})

// 注册过滤器
import * as filters from './advanced/filters'
Object.keys(filters).forEach(name => {
  Vue.filter(name, filters[name])
})

// 
import { VueJsonp } from 'vue-jsonp'
Vue.use(VueJsonp)



window.onload = function() {
  // 接收domain1的数据
  console.log('init login')
  window.addEventListener('message', function(e) {
    let data = e.data
    try {
      data = JSON.parse(data);
    } catch(err) {
      console.log('ps', err)
      // data = {}
    }

    if(data.from == 'sm_crx') {
      console.log('sm_web 收到来之', data)
    }
  }, false);
}


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


console.log('NODE_ENV:', process.env.NODE_ENV)