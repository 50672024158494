import Vue from 'vue'
import Router from 'vue-router'

// 
import login from './login'
// import home from './home'

Vue.use(Router)
let routes = []
.concat(login)
// .concat(home)


const router = new Router({
  mode: 'history',
  base: '',
  routes
})


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

// router.afterEach(route => {
//   console.log(route)
// })



export default router
