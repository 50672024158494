export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login'),
    meta: {
      title: '瞬马-pdd工具-登录'
    }
  }, {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/regi.vue'),
    meta: {
      title: '瞬马-pdd工具-注册'
    }
  }, {
    path: '/misspsw',
    name: 'misspsw',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/missPsw.vue'),
    meta: {
      title: '瞬马-pdd工具-忘记密码'
    }
  }, {
    path: '/settpsw',
    name: 'settpsw',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/settPsw.vue'),
    meta: {
      title: '瞬马-pdd工具-设置密码'
    }
  }
]